body {
  font-family: 'Radley', serif;
}

header {
  position: fixed;
  height: 100%;
  width: 20%;
  padding: 2rem;
  font-size: 18px;
  background-color: #FFFFFF;
  z-index: 1;
}

section {
  margin-left: 22rem;
}

a {
  text-decoration: none;
}

.redirectContent {
  margin: auto;
  text-align: center;
  align-items: center;
  margin-top: 20rem;
  color: #562626;
}

.redirectContent a {
  font-size: 20px;
  color: #1A3D83;
  text-decoration: underline;
}

.continue {
  width: 30px;
  height: 30px;
  border-right: 4px solid;
  border-top: 2px solid;
  margin: auto;
  transform: rotate(135deg);
  margin-top: 20vh;
  border-color: #957676;
}

.continue:hover {
  cursor: pointer;
}

.headerStyling {
  display: none;
}

.font-small {
  font-size: small;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.padding-top-small {
  padding-top: 2rem;
}

.text-indent-medium {
  text-indent: 4rem;
}

.text-indent-large {
  text-indent: 8rem;
}

.no-top-margin {
  margin-top: 0rem;
}

.margin-left-medium {
  margin-left: 4rem;
}

/* About styles */

.styling {
  margin-top: 2rem;
  height: 100%;
  width: 90%;
  border-right: 2px solid;
  border-color: #957676;
}

.headerSections {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 30%;
  border-right: 2px solid;
  border-color: #957676;
  height: 100%;
}

.headerSections a {
  padding: 1rem;
  color: #390B0B;
  font-weight: bold;
}

.aboutInfo a {
  color: #000000;
  text-decoration: underline;
}

.aboutInfo div span:hover {
  cursor: pointer;
}

.aboutInfo div h1 {
  white-space: nowrap;
}

.aboutPage {
  margin-left: 30%;
  height: auto;
  font-size: 18px;
}

.homepage {
  display: flex;
  flex-direction: row;
}

.homepage div p {
  margin-left: 20px;
}

.profile {
  width: 20vw;
  height: auto;
  border-radius: 50%;
  margin-top: 34vh;
  opacity: 0.9;
}

.about {
  margin-top: 34vh;
  margin-left: 5rem;
  margin-right: 4rem;
  width: 30vw;
}

.news {
  padding-top: 1rem;
  font-size: 16px;
}

.news_scroll {
  height: 15rem;
  overflow-y: auto;
}

.news h3 {
  text-align: center;
  border-bottom: 1px solid black;
}

.info p {
  border-bottom: 1px dotted black;
}

/* Sections Style */

.research, .professional, .projects {
  margin-left: 30%;
  padding-top: 2rem;
}

.experience {
  display: flex;
  flex-direction: column;
}

.details {
  padding-left: 1rem;
  width: 56%;
  margin-right: 2rem;
}

.projectDetails {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

/* Research Section */

.research .block {
  width: 18rem;
  height: auto;
  margin: 2rem;
}

.publication {
  margin-left: 2rem;
  width: 80%
}

.publication a, .projectDetails a, .about a {
  text-decoration: underline;
  color: #1A3D83;
}


/* Professional Section */

.professional .block {
  width: 80%;
  height: auto;
  margin: 2rem;
  border-left: 0.5rem solid;
  border-color: #906D6D;
  padding: 0rem 1rem 0rem 2rem;
}

.projects .experience {
  flex-direction: row;
  flex-wrap: wrap;
}

.projects .block {
  width: 20rem;
  height: 16rem;
  background-color: #E3E0F0;
  margin: 2rem;
  margin-left: 6rem;
}

.award {
  color: #AB3319;
}

footer {
  margin-top: 4rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #C1B4B4;
  font-weight: bold;
}

@media (max-width: 768px) {
  header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    justify-content: space-between;
    padding: 0rem 2rem 0.5rem 2rem;
  }

  .headerStyling {
    width: 30px;
    height: 30px;
    margin: 1.5rem 3rem 2rem 5rem;
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: #957676;
    display: block;
  }

  .headerSections {
    margin: 0rem;
    padding-left: 1rem;
    padding-right: 2rem;
    flex-direction: row;
    justify-content: center;
    border: none;
    height: auto;
  }

  .headerSections a {
    padding-top: 0rem;
  }

  .styling {
    border: none;
    display: none;
  }

  .aboutPage {
    margin: 1rem;
    position: relative;
    height: 60%;
  }

  .aboutInfo, .aboutInfo div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .aboutInfo a {
    display: none;
  }

  .aboutInfo p {
    padding: 2rem 0rem 0rem 1rem;
  }

  .profile {
    width: 16rem;
    vertical-align: center;
    margin-top: 23vh;
  }

  .homepage, .about {
    width: 90vw;
  }

  .homepage, .homepage div {
    flex-direction: column;
    align-items: center;
  }

  .about {
    margin: 0rem 0rem 2rem 1rem;
  }

  .continue {
    margin: auto;
  }

  .research, .professional, .projects {
    margin: 1rem;
  }

  .details p, .details li {
    padding: 0rem 1rem 0rem 1rem;
  }

  .projectDetails {
    flex-direction: column;
    align-items: center;
  }

  .details {
    align-items: center;
    width: 100%;
    padding: 0rem;
    margin: auto;
  }

  .professional .block, .research .block {
    margin: 1rem;
  }

  .professional .experience {
    margin-top: 3rem;
  }
}

